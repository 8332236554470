import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EEE7DE", 
    },
    secondary: {
      main: "#4a6741", 
      experience: "#d6d0c8",
    },
  },
  width: {
    xs: "90%",
    md: "75%",
  }
});

export default theme;
