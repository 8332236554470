import * as React from "react";
import { Box, Grid } from "@mui/material";
import heb from "../images/heb symbol.svg";
import transfer from "../images/ut image.svg";
import apl from "../images/apl button.svg";
import hudl from "../images/hudl.svg";
import HEBInfo from "./HEBInfo";
import TransferInfo from "./TransferInfo";
import APLInfo from "./APLInfo";
import HudlInfo from "./HudlInfo";
import { useTheme } from "@mui/material/styles";

export default function Experience(props) {
  const theme = useTheme();
  return (
    <div>
      <Box>
        <h4 style={{ marginBottom: "30px" }}>My Recent Experience</h4>
      </Box>
      <Box sx={{ width: "75%", margin: "auto" }}>
        <Grid container spacing={3} sx={{ marginTop: "30px" }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            onClick={props.handleHudlClick}
            style={{
              cursor: "pointer",
              paddingBottom: "0px",
            }}
            id="hudl-info"
          >
            <img
              src={hudl}
              style={{ width: "100%", height: "auto" }}
              alt="hudl"
              className="hover-effect"
            />
            {props.hudlOpen && (
              <HudlInfo anchorEl={props.hudlAnchorEl}></HudlInfo>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            onClick={props.handleHEBClick}
            style={{
              cursor: "pointer",
              paddingBottom: "0px",
            }}
            id="heb-info"
          >
            <img
              src={heb}
              style={{ width: "100%", height: "auto" }}
              alt="HEB"
              className="hover-effect"
            />
            {props.hebOpen && <HEBInfo anchorEl={props.hebAnchorEl}></HEBInfo>}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            onClick={props.handleTransferClick}
            style={{ cursor: "pointer" }}
            id="transfer-info"
          >
            <img
              src={transfer}
              style={{ width: "100%", height: "auto" }}
              alt="Transfer"
              className="hover-effect"
            />
            {props.transferOpen && (
              <TransferInfo anchorEl={props.transferAnchorEl}></TransferInfo>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            onClick={props.handleAPLClick}
            style={{ cursor: "pointer" }}
            id="apl-info"
          >
            <img
              src={apl}
              style={{ width: "100%", height: "auto" }}
              alt="APL"
              className="hover-effect"
            />
            {props.aplOpen && <APLInfo anchorEl={props.aplAnchorEl}></APLInfo>}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
