import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import hebVideo from "../images/heb-video.mov";
import { useTheme } from "@mui/material/styles";

const HEBInfo = (props) => {
  const theme = useTheme();
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [boxHeight, setBoxHeight] = React.useState("1000px");

  useEffect(() => {
    if (props.anchorEl) {
      const anchorRect = props.anchorEl.getBoundingClientRect();
      setPosition({
        left: anchorRect.left + anchorRect.width / 2 - 20,
        top: anchorRect.bottom,
      });
    }
    const box = document.getElementById("heb-box");
    setBoxHeight(box.getBoundingClientRect().height + "px");
    console.log(box.getBoundingClientRect().height);
  }, [props.anchorEl]);

  return (
    <div style={{ marginTop: "30px", height: boxHeight, display: "flex" }}>
      <Box
        id="heb-box"
        sx={{
          position: "absolute",
          height: "fit-content",
          backgroundColor: theme.palette.secondary.experience,
          width: "100% !important",
          left: "0px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-25px",
            left: position.left,
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "45px solid transparent",
            borderRight: "45px solid transparent",
            borderBottom: "45px solid" + theme.palette.secondary.experience,
          }}
        ></Box>
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Box>
            <h4 style={{ marginBottom: "20px" }}>
              HEB Blackout Control Panel (BCP)
            </h4>
            <h3>
              The BCP is a tool utilized by HEB partners to communicate an item
              outage in the store to the website for consumers.
            </h3>
          </Box>
          <Box>
            <video
              controls
              autoPlay
              loop
              muted
              width="98%"
              height="auto"
              style={{ margin: "auto", borderRadius: "10px" }}
            >
              <source src={hebVideo} type="video/mp4" />
            </video>
          </Box>
          <Box sx={{ margin: "20px" }}>
            <Box>
              <h3 style={{ textAlign: "left" }}>OVERVIEW</h3>
              <h2 style={{ textAlign: "left", fontWeight: "300" }}>
                The summer of 2023, I interned for the grocery store company HEB
                as a software engineer intern. My project focused on building
                out the Blackout Control Panel, which took the process of
                inputting out of stock items from 30 minutes to 2 minutes.
              </h2>
            </Box>
            <Divider horizontal sx={{ margin: "20px" }}></Divider>
            <Grid container>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TIMELINE
                </h3>
                <h3 style={{ textAlign: "left" }}>June-July 2023</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>SKILLS</h3>
                <h3 style={{ textAlign: "left" }}>
                  React JS <br />
                </h3>
                <h3 style={{ textAlign: "left" }}>MUI Component Library</h3>
                <h3 style={{ textAlign: "left" }}>
                  Front and Backend Integration
                </h3>
                <h3 style={{ textAlign: "left" }}>Figma Graphics</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  MY ROLE
                </h3>
                <h3 style={{ textAlign: "left" }}>Software Engineer</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TAKAWAYS
                </h3>
                <h3 style={{ textAlign: "left" }}>
                  Learning and enjoying front-end development
                </h3>
                <h3 style={{ textAlign: "left" }}>
                  How to work with a designer and a team
                </h3>
                <h3 style={{ textAlign: "left" }}>React JS</h3>
                <h3 style={{ textAlign: "left" }}>
                  How to do basic React testing
                </h3>
                <h3 style={{ textAlign: "left" }}>
                  How engineering teams function (tickets, standup)
                </h3>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default HEBInfo;
