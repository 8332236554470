import * as React from "react";
import { Box } from "@mui/material";
import spotify from "../images/spotify.svg";
import { useTheme } from "@mui/material/styles";

export default function Footer(props) {
  const theme = useTheme();
  const LinkedIn = "https://www.linkedin.com/in/raina-parikh/";

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Switch to column on smaller screens
          justifyContent: "space-between",
          //alignItems: "center",
          paddingTop: { xs: "20px", md: "50px" }, // Adjust padding for smaller screens
          paddingBottom: { xs: "20px", md: "50px" }, // Adjust padding for smaller screens
        }}
      >
        <Box
          sx={{
            width: "auto",
            marginRight: { xs: "0px", md: "200px" },
            marginBottom: { xs: "20px", md: "0px" },
          }}
        >
          <h1
            style={{
              fontSize: { xs: "24px", md: "36px" }, // Adjust font size for smaller screens
              marginBottom: "10px",
              textAlign: "left",
            }}
          >
            Thanks for dropping by!
          </h1>
          <h3
            style={{ textAlign: "left", fontSize: { xs: "16px", md: "18px" } }}
          >
            Questions, comments, concerns, or a good music recommendation? Feel
            free to connect with me to chat about all the silly things.
          </h3>
          <Box
            sx={{
              width: "auto",
              backgroundColor: theme.palette.primary.main,
              textAlign: "left",
              borderRadius: "40px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={spotify}
              height="50px"
              style={{ margin: "15px" }}
              alt="Spotify"
            />
            <a
              href="https://open.spotify.com/track/4JeOesjcWWlprTAvucDSJX?si=4182a87b025649f7"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <Box
                sx={{
                  marginRight: "25px",
                }}
              >
                <h3
                  style={{
                    color: theme.palette.secondary.main,
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                >
                  On Repeat
                </h3>
                <h3
                  style={{
                    color: theme.palette.secondary.main,
                    fontWeight: "600",
                    margin: "0px",
                  }}
                >
                  People Watching by Conan Gray
                </h3>
              </Box>
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "row" }, // Switch to column on smaller screens
            // justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              textAlign: "right",
              flexShrink: 0,
              marginRight: { xs: "20px", md: "50px" },
            }}
          >
            <h3 style={{ fontWeight: "600" }}>SAY HELLO</h3>
            <a
              href={LinkedIn}
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h3>LinkedIn</h3>
            </a>
            <a
              href="mailto:rainaparikh@utexas.edu"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h3>Email</h3>
            </a>
            <a
              href={props.resume}
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h3>Resume</h3>
            </a>
          </Box>
          <Box sx={{ textAlign: "right", flexShrink: 0 }}>
            <h3 style={{ fontWeight: "600" }}>NAVIGATION</h3>
            <h3 style={{ cursor: "pointer" }} onClick={props.handleScrollHome}>
              Home
            </h3>
            <h3
              style={{ cursor: "pointer" }}
              onClick={props.handleScrollExperience}
            >
              Experience
            </h3>
            <h3 style={{ cursor: "pointer" }} onClick={props.handleScrollAbout}>
              About
            </h3>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
