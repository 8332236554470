import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TransferBoxes from "./TransferBoxes";

const TransferInfo = (props) => {
  const theme = useTheme();
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [boxHeight, setBoxHeight] = React.useState("1000");

  const [imageLoaded, setImagesLoaded] = React.useState(0);

  useEffect(() => {
    if (props.anchorEl) {
      const anchorRect = props.anchorEl.getBoundingClientRect();
      setPosition({
        left: anchorRect.left + anchorRect.width / 2 - 20,
        top: anchorRect.bottom,
      });
    }
    const box = document.getElementById("transfer-box");
    setBoxHeight(box.getBoundingClientRect().height + "px");
    console.log(box.getBoundingClientRect().height);
  }, [props.anchorEl, imageLoaded]);

  return (
    <div
      style={{
        marginTop: "30px",
        height: boxHeight,
        display: "flex",
      }}
    >
      <Box
        id="transfer-box"
        sx={{
          position: "absolute",
          height: "fit-content",
          backgroundColor: theme.palette.secondary.experience,
          width: "100% !important",
          left: "0px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-25px",
            left: position.left,
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "45px solid transparent",
            borderRight: "45px solid transparent",
            borderBottom: "45px solid" + theme.palette.secondary.experience,
          }}
        ></Box>
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Box>
            <h4 style={{ marginBottom: "20px" }}>TransferIQ</h4>
            <h3>
              UT transfer portal currently providing statistical insights for
              both men's and women's basketball teams and transitioning into
              football
            </h3>
          </Box>
          <TransferBoxes setImagesLoaded={setImagesLoaded}></TransferBoxes>
          <Box sx={{ margin: "20px" }}>
            <Box>
              <h3 style={{ textAlign: "left" }}>OVERVIEW</h3>
              <h2 style={{ textAlign: "left", fontWeight: "300" }}>
                Beginning in the fall of 2023, I joined the TransferIQ team as a
                frontend developer, working on adding features and mobilizing
                the platform. TransferIQ is led by Professor Kirk Goldsberry.
              </h2>
            </Box>
            <Divider horizontal sx={{ margin: "20px" }}></Divider>
            <Grid container>
              <Grid item xs={6} md={4}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TIMELINE
                </h3>
                <h3 style={{ textAlign: "left" }}>August 2023 - </h3>
              </Grid>
              <Grid item xs={6} md={4}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>SKILLS</h3>
                <h3 style={{ textAlign: "left" }}>
                  React JS <br />
                </h3>
                <h3 style={{ textAlign: "left" }}>MUI Component Library</h3>
                <h3 style={{ textAlign: "left" }}>Figma Graphics</h3>
              </Grid>
              <Grid item xs={6} md={4}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  MY ROLE
                </h3>
                <h3 style={{ textAlign: "left" }}>Frontend Developer</h3>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default TransferInfo;
