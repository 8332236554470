import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import evergreen from "../images/evergreen-tree.svg";
import sun from "../images/sun.svg";
import moon from "../images/moon-and-stars.svg";

export default function Title(props) {
  const theme = useTheme();
  return (
    <div>
      {props.isSun ? (
        <div
          style={{
            position: "absolute",
            top: "150px",
            marginLeft: "50px",
            zIndex: "5",
            //animation: `${props.isSun ? "move-in" : "move-out"} 2s ease-out`,
            animation: `${props.isSun ? "fadeIn" : "move-out"} 2s ease-out`,
          }}
        >
          <img src={sun} height="100px" alt="sun image"></img>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "150px",
            marginLeft: "50px",
            zIndex: "6",
          }}
        >
          <img src={moon} height="100px" alt="moon image"></img>
        </div>
      )}
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100%",
          // width: { xs: theme.width.xs, md: theme.width.md },
          //justifyContent: "center",
        }}
      >
        <Box sx={{ flexShrink: 1, marginTop: { xs: "200px", md: "300px" } }}>
          <h1>
            Hi there, I'm{" "}
            <span
              style={{
                color: props.isSun ? "#4a6741" : "white",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onMouseOver={() => props.setIsSun(false)}
              onMouseOut={() => props.setIsSun(true)}
            >
              Raina Parikh.
            </span>
          </h1>
          <h1>A software engineer.</h1>
          <h2 style={{ color: "gray" }}>
            previously @Hudl, currently @UTAustin
          </h2>
        </Box>
        <div
          style={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "block" }, // Hide on xs, show on md and above
              // other styles...
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: "200px",
                position: "absolute",
                zIndex: "1",
              }}
            >
              <img src={evergreen} height="400px" alt="First Tree" />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "100px",
                position: "absolute",
                zIndex: "2",
                marginLeft: "100px",
              }}
            >
              <img src={evergreen} height="500px" alt="Third Tree" />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "300px",
                position: "absolute",
                zIndex: "3",
                marginLeft: "250px",
              }}
            >
              <img
                src={evergreen}
                height="300px"
                alt="Second Tree"
                style={{ transform: "scaleX(-1)" }}
              />
            </div>
          </Box>
        </div>
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            textAlign: "center",
            //width: "100%",
            width: { xs: theme.width.xs, md: theme.width.md },
            color: "black",
            animation: "bounce 2s infinite",
            cursor: "pointer",
          }}
          onClick={props.handleScroll}
        >
          <KeyboardArrowDown sx={{ fontSize: "48px" }}></KeyboardArrowDown>
        </Box>
      </div>
    </div>
  );
}
