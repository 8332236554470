import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import figjam from "../images/jamboard.svg";
import interview from "../images/interview.png";
import { useTheme } from "@mui/material/styles";

const APLBoxes = (props) => {
  const theme = useTheme();

  const handleImageLoad = () => {
    props.setImagesLoaded((prev) => prev + 1);
  };

  return (
    <div style={{ height: "fit-content" }}>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "fit-content",
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <img
                  src={figjam}
                  alt="FigJam"
                  style={{
                    width: "90%",
                    borderRadius: "40px",
                    padding: "20px",
                  }}
                  onLoad={handleImageLoad}
                ></img>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <Box sx={{ margin: "30px" }}>
                <h3 style={{ fontWeight: "700" }}>STEP 1: BRAINSTORMING</h3>
                <h3>
                  We began using Figma JamBoard to map out user flow, carefully
                  marking what areas need to be improved. We moved to analyzing
                  other library websites to test their user flow against ours
                  and marked out our problem statement
                </h3>
                <Box
                  sx={{
                    backgroundColor: theme.palette.secondary.experience,
                    width: "100%",
                    height: "115px",
                    borderRadius: "20px",
                    marginBottom: "30px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%" // You can adjust this to your preferred height
                    sx={{ padding: "5px" }}
                  >
                    <h3 style={{ margin: "5px" }}>PROBLEM</h3>
                    <h3
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        fontWeight: "700",
                      }}
                    >
                      Finding, editing, and entering events is difficult for the
                      librarians and library goers
                    </h3>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ borderRadius: "30px" }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              height: "fit-content",
              width: "100%",
              borderRadius: "30px",
              marginBottom: "30px",
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
                <Box sx={{ margin: "30px" }}>
                  <h3 style={{ fontWeight: "700" }}>STEP 2: USER INTERVIEWS</h3>
                  <h3>
                    We interviewed library goers of all types, from families to
                    teenagers, to grasp how people find library events. They
                    preferred to have the information easily accessible on the
                    website (ability to shift through multiple events) and said
                    the library website would be there first stop for event
                    information.
                  </h3>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.secondary.experience,
                      width: "100%",
                      height: "115px",
                      borderRadius: "20px",
                      marginBottom: "30px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                      justifyContent="left"
                      height="100%" // You can adjust this to your preferred height
                      sx={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "30px",
                      }}
                    >
                      <h3 style={{ margin: "5px" }}>PRIORITIES</h3>
                      <h3
                        style={{
                          textAlign: "left",
                          margin: "5px",
                          fontWeight: "700",
                        }}
                      >
                        1. Age <br />
                        2. Time <br />
                        3. Location
                      </h3>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <img
                    src={interview}
                    alt="Interview Script"
                    style={{
                      width: "90%",
                      borderRadius: "40px",
                      padding: "20px",
                    }}
                    onLoad={handleImageLoad}
                  ></img>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box sx={{ borderRadius: "30px" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "150px", // should be fit-content
            width: "100%",
            borderRadius: "30px",
            marginBottom: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 style={{ fontWeight: "700" }}>
            This project is a work in progress! Check back for updates
          </h3>
        </Box>
      </Box>
    </div>
  );
};

export default APLBoxes;
