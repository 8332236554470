import * as React from "react";
import { Box } from "@mui/material";

export default function Header(props) {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box sx={{ width: "auto" }}>
          <h3>RAINA PARIKH</h3>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
          }}
        >
          <h3 style={{ margin: "15px", cursor:"pointer" }} onClick={props.handleScrollExperience}>Experience</h3>
          <h3 style={{ margin: "15px", cursor:"pointer"  }} onClick={props.handleScrollAbout}>About</h3>
          <a
            href={props.resume}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit", margin: "15px" }}
          >
            <h3>Resume</h3>
          </a>
        </Box>
      </Box>
    </div>
  );
}
