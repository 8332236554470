import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const HudlInfo = (props) => {
  const theme = useTheme();
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [boxHeight, setBoxHeight] = React.useState("1000px");

  useEffect(() => {
    if (props.anchorEl) {
      const anchorRect = props.anchorEl.getBoundingClientRect();
      setPosition({
        left: anchorRect.left + anchorRect.width / 2 - 20,
        top: anchorRect.bottom,
      });
    }
    const box = document.getElementById("hudl-box");
    setBoxHeight(box.getBoundingClientRect().height + "px");
    console.log(box.getBoundingClientRect().height);
  }, [props.anchorEl]);

  return (
    <div style={{ marginTop: "30px", height: boxHeight, display: "flex" }}>
      <Box
        id="hudl-box"
        sx={{
          position: "absolute",
          height: "fit-content",
          backgroundColor: theme.palette.secondary.experience,
          width: "100% !important",
          left: "0px",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "-25px",
            left: position.left,
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "45px solid transparent",
            borderRight: "45px solid transparent",
            borderBottom: "45px solid" + theme.palette.secondary.experience,
          }}
        ></Box>
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <Box>
            <h4 style={{ marginBottom: "20px" }}>Hudl</h4>
          </Box>
          <Box sx={{ margin: "20px" }}>
            <Box>
              <h3 style={{ textAlign: "left" }}>OVERVIEW</h3>
              <h2 style={{ textAlign: "left", fontWeight: "300" }}>
                The summer of 2024, I interned for the sports technology company
                Hudl as a software engineer. I worked on tickets to meet the
                sprint goal, including on-demand rendering, mp4 rendition
                creation, and creating endpoints to assist with auto deletion of
                unused video.
              </h2>
            </Box>
            <Divider horizontal sx={{ margin: "20px" }}></Divider>
            <Grid container>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TIMELINE
                </h3>
                <h3 style={{ textAlign: "left" }}>May-August 2024</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>SKILLS</h3>
                <h3 style={{ textAlign: "left" }}>
                  C# and dotnet <br />
                </h3>
                <h3 style={{ textAlign: "left" }}>MUI Component Library</h3>
                <h3 style={{ textAlign: "left" }}>API Endpoint Creation</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  MY ROLE
                </h3>
                <h3 style={{ textAlign: "left" }}>Software Engineer</h3>
              </Grid>
              <Grid item xs={6} md={3}>
                <h3 style={{ textAlign: "left", fontWeight: "700" }}>
                  TAKAWAYS
                </h3>
                <h3 style={{ textAlign: "left" }}>
                  Learning how to use dotnet and C#
                </h3>
                <h3 style={{ textAlign: "left" }}>Writing API tests</h3>
                <h3 style={{ textAlign: "left" }}>
                  Working to meet a sprint goal
                </h3>
                <h3 style={{ textAlign: "left" }}>Completing dev testing</h3>
                <h3 style={{ textAlign: "left" }}>
                  Deploying changes to production
                </h3>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default HudlInfo;
