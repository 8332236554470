import * as React from "react";
import { Box, Grid, Divider } from "@mui/material";

import hookem from "../images/hookem.png";
import computer from "../images/web-design.png";
import strategy from "../images/strategy-hand-drawn-sketch.png";
import lightbulb from "../images/light-bulb-outlined-hand-drawn-tool.png";
import mentor from "../images/professor-teaching.png";
import book from "../images/text-book-hand-drawn-outline.png";
import heb from "../images/HEB.png";
import hudl from "../images/hudl drawn logo.png";
import { useTheme } from "@mui/material/styles";

export default function Experience(props) {
  const theme = useTheme();
  const content = (
    <>
      <span style={{ fontWeight: "700" }}>
        Hello! Howdy! Welcome to my portfolio!{" "}
      </span>
      <br />
      <br />
      I hope you had fun taking a look around and enjoyed the sunrise. My
      passion for software began long ago, when I, begrudgingly I might add,
      attended my first Girls Who Code meeting. While I might have started out
      hesitant, I immediately fell in love and it has been history ever since :)
      <br />
      <br />
      You will always find me reading a book on my Kindle and listening to music
      (recommendations are always welcomed). I love baking new treats and
      watching Texas football! Hook 'em Horns!
    </>
  );

  const life = (
    <>
      <span style={{ fontWeight: "700" }}>LIFE AT A GLANCE</span>
      <br />
      <br />
      <span style={{ fontWeight: "700" }}>Current Jobs</span>
      <ul>
        <li>
          TransferIQ{" "}
          <span style={{ display: "block", fontStyle: "italic" }}>
            Research Assistant - Frontend Engineer
          </span>
        </li>
        <li>
          UT Austin Department of Computer Science
          <span style={{ display: "block", fontStyle: "italic" }}>
            Pod Mentor
          </span>
        </li>
      </ul>
      <br />
      <span style={{ fontWeight: "700" }}>Current Obsessions</span>
      <ul>
        <li>University of Texas Football</li>
        <li>Taylor Swift, AJR, Conan Gray, & Gracie Abrams</li>
        <li>Scrapbooking</li>
        <li>Books!</li>
      </ul>
    </>
  );

  return (
    <div>
      <Box sx={{ marginBottom: "30px" }}>
        <h4>About Me</h4>
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item xs>
            <h3 style={{ textAlign: "left" }}>{content}</h3>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ margin: "20px" }} />
          <Grid item xs>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={hookem}
                style={{
                  height: "40px",
                  marginRight: "15px",
                  color: theme.palette.secondary.main,
                }}
              ></img>
              <h3>University of Texas at Austin</h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={computer}
                style={{
                  height: "45px",
                  marginRight: "15px",
                  color: theme.palette.secondary.main,
                }}
              ></img>
              <h3>
                Pursuing a B.S. in{" "}
                <span style={{ fontWeight: "700" }}>Computer Science</span> with
                an Applied Statistical Modeling Certficate
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={lightbulb}
                style={{
                  height: "45px",
                  marginRight: "15px",
                  color: theme.palette.secondary.main,
                }}
              ></img>
              <h3>
                Currently the{" "}
                <span style={{ fontWeight: "700" }}>President</span> of Texas
                Product Engineering Organization
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={strategy}
                style={{
                  height: "40px",
                  marginRight: "20px",
                  color: theme.palette.secondary.main,
                }}
              ></img>
              <h3>
                Currently a{" "}
                <span style={{ fontWeight: "700" }}>
                  frontend engineer at TransferIQ
                </span>{" "}
                - a transfer portal software for UT sports
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={mentor}
                style={{
                  height: "45px",
                  marginRight: "15px",
                  color: theme.palette.secondary.main,
                }}
              ></img>
              <h3>Currently a Pod Mentor for the UT Department of CS</h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={hudl}
                style={{
                  height: "28px",
                  marginRight: "30px",
                  marginTop: "5px",
                  marginLeft: "2px",
                }}
              ></img>
              <h3>
                Previously a{" "}
                <span style={{ fontWeight: "700" }}>
                  software engineer intern
                </span>{" "}
                at Hudl
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={heb}
                style={{
                  height: "28px",
                  marginRight: "17px",
                  marginTop: "5px",
                  marginLeft: "2px",
                }}
              ></img>
              <h3>
                Previously a{" "}
                <span style={{ fontWeight: "700" }}>
                  software engineer intern
                </span>{" "}
                at HEB
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <img
                src={book}
                style={{
                  height: "38px",
                  marginRight: "20px",
                  marginTop: "5px",
                  marginLeft: "2px",
                }}
              ></img>
              <h3>Obsessed with books and music!!</h3>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
